.resource-link {
  border-radius: 5px;
  padding: 5px;
  white-space: nowrap;
  color: $link;
  &.resource-link-deleted {
    span {
      text-decoration: line-through;
    }
    &:hover {
      color: inherit;
      text-decoration: inherit;
      svg {
        color: $link;
      }
    }
  }
  &.resource-link-broken {
    color: $danger;
    &:hover {
      color: $danger;
      text-decoration: inherit;
      svg, svg image, svg use {
        fill: $danger;
        color: $danger;
      }
    }
    svg, svg image, svg use {
      fill: $danger;
      color: $danger;
    }
  }
  &:hover {
    color: $link;
    text-decoration: underline;
  }
  svg, svg image, svg use {
    fill: $link;
    color: $link;
  }
  svg+span {
    margin-left: 5px;
  }
  svg {
    image, use {
      width: 100%;
    }
  }
}

// Shim for fontawesome 5/react
.svg-inline--fa.fa-w-12 {
  width: 0.75em
}