




































































































































































































































































































































































































.navbar-item {
  img {
    max-height: 2.75rem;
  }
  .navbar-photo-small {
    img {
      max-height: 1.5rem;
    }
  }
}

.dropdown-content div a svg,
.dropdown-content a svg {
  min-width: 18px;
}
