/**
 * Overrides for buefy.css
 */

.modal {
  z-index: 500;
  &.modal-content {
    width: inherit;
    min-width: 30vw;
  }
}

.table td, .table th {
  vertical-align: middle;
}

$square-image-dimensions: 192, 256, 480, 512;

.image {
  @each $dimension in $square-image-dimensions{
    &.is-#{$dimension}x#{$dimension} {
      height: #{$dimension}px;
      width: #{$dimension}px;
    }
  }
}

.taginput.control {
  div.taginput-container:not(.is-focusable) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.icon.is-small svg {
    width: 1rem;
    height: 1rem;
}