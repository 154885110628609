/*
 Custom quill scss styling: this style is required because some notes have been generated with quill editor
 and would not be rendered properly if this style was removed
 */

$content-box-sizes: (
  "small": 50px,
  "medium": 100px,
  "large": 150px,
  "x-large": 200px
);

@each $name, $value in $content-box-sizes {
  .has-#{$name}-content-box .ql-editor {
    min-height: #{$value};
  }
}

.ql-container {
  max-height: 450px;
  overflow-y: scroll;
}

.ql-tooltip {
  left: 0 !important;
}

@for $i from 1 to 9 {
  .ql-indent-#{$i} {
    margin-left: 20px * $i;
  }
}

.content ol {
  margin-top: 0.5em;
  & ol:not([type]) {
    list-style-type: lower-latin;
    & ol:not([type]) {
      list-style-type: lower-roman;
      & ol:not([type]) {
        list-style-type: decimal;
        & ol:not([type]) {
          list-style-type: lower-latin;
          & ol:not([type]) {
            list-style-type: lower-roman;
            & ol:not([type]) {
              list-style-type: decimal;
              & ol:not([type]) {
                list-style-type: lower-latin;
                & ol:not([type]) {
                  list-style-type: lower-roman;
                  & ol:not([type]) {
                    list-style-type: decimal;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
