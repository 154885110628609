.check-button {
  color: $gray;
  cursor: pointer;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-dark: darken(nth($pair, 1), 10%);
    &.is-#{$name} {
      &:hover {
        color: $color;
      }
      &:active {
        color: $color-dark;
      }
    }
  }
}
