// Custom froala styling
@mixin border-radius($pixel...) {
  -webkit-border-radius: $pixel;
  -moz-border-radius: $pixel;
  -ms-border-radius: $pixel;
  -o-border-radius: $pixel;
  border-radius: $pixel;
}

.fr-box.fr-basic, .fr-toolbar.fr-top{
  @include border-radius($radius $radius 0 0 !important);
}

.fr-second-toolbar{
  @include border-radius(0 0 $radius $radius !important);
}

.fr-box.fr-basic .fr-element{
  font-family: unset !important;
  color: unset !important;
  font-size: unset !important;
  line-height: unset !important;
}

.fr-view table {
  margin-left:auto;
  margin-right: auto;
}

.fr-view table td{
  border-bottom-width: 1px !important;
}

.fr-view table td.fr-thick{
  border-bottom-width: 2px !important;
}

.content p:not(:last-child),
.content dl:not(:last-child),
.content ol:not(:last-child),
.content ul:not(:last-child),
.content blockquote:not(:last-child),
.content pre:not(:last-child),
.content table:not(:last-child) {
  margin-bottom: unset;
}