// #ffe3e3

.box {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      -webkit-box-shadow:  0 2px 3px rgba($color, 0.1), 0 0 0 2px rgba($color, 0.1);
      box-shadow: 0 2px 3px rgba($color, 0.1), 0 0 0 2px rgba($color, 0.1);
    }
  }
}
