.min-width-fit-content {
  min-width: fit-content;
}

.flex-1 {
  flex: 1;
}

.is-flex-1 {
  flex: 1;
}

// Gaps value inspired from bulma spacing rules
.gap, .gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.5rem;
}

.gap-6 {
  gap: 3rem;
}

.is-clickable {
  cursor: pointer !important;
}

.has-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.is-flex-1 {
  flex: 1;
}

// Gaps value inspired from bulma spacing rules
.gap, .gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.5rem;
}

.gap-6 {
  gap: 3rem;
}

.is-clickable {
  cursor: pointer !important;
}

.mw-0 {
  min-width: 0; /* Often used as a hack to fix ellipsis */
}

@media print {
  .is-hidden-print {
    display: none !important;
  }
}