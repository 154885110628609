// custom styling for Tribute pop-up
.tribute-container {
  margin-top: 6px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  ul {
    li {
      padding: 5px;
      font-weight: 700;
      &.tribute-highlight {
        color: #fff;
        background-color: $link;
      }
    }
  }
}

.tribute-item-with-image {
  display: flex;
  div {
    margin-right:4px;
    word-break: break-all;
    img, svg {
      height: 22px;
      width: 22px;
      padding: 2px;
    }
  }
}
