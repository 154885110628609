@-webkit-keyframes highlight {
  0% {
    opacity: 0;
  }
  2% {
    background-color: #ffffbb;
    opacity: 1;
  }
  100% {
    background: white;
  }
}

.highlight-new {
  -webkit-animation-name: highlight;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
}
