// https://codepen.io/sosuke/pen/Pjoqqp
// target = $gray: #d3d3d3;
img.black-to-gray {
  filter: invert(78%) sepia(0%) saturate(7494%) hue-rotate(138deg) brightness(103%) contrast(109%);
}

.is-32x32 {
  width: 32px;
  height: 32px;
}
