// Taken from https://github.com/komayuki/vue-transition-css

.slide-right-enter-active{
  animation: slideRight .8s;
}
.slide-right-leave-active {
  animation: slideRight .8s reverse;
}
@keyframes slideRight {

  0% {
    transform: translate3d(-30px, 0, 0);
  }
  50% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: translate3d(0px, 0, 0);
  }
}