// General
html {
  scroll-behavior: smooth;
}

html, body, #app {
  min-height: 100%;
}

ul, ol {
  &.is-horizontal-list li {
    &:not(:first-child) {
      padding-left: 8px;
    }
    svg + a {
      margin-left: 10px;
    }
    a + svg {
      margin-left: 10px;
    }

    display: inline;
  }
}

.card-footer {
  .card-footer-item {
    svg + span {
      margin-left: 10px;
    }
    span + svg {
      margin-left: 10px;
    }
  }
}

.icon-text {
  svg + span {
    margin-left: 6px;
  }
  span + svg {
    margin-left: 6px;
  }
  svg + a {
    margin-left: 6px;
  }
  a + svg {
    margin-left: 6px;
  }
}

.align-info-explainer {
  display: flex;
  align-content: center;
  white-space: nowrap;
  height: 25px;
  line-height: 17px;
  &.is-size-3 {
    height: 50px;
    line-height: 35px;
  }
  &.modal-card-title {
    height: 40px;
    line-height: 28px;
  }
}

.tag.file-tag {
  svg+a {
    margin-left: 10px;
  }
  a+button {
    margin-left: 10px;
  }
}

.tag:not(body).is-small {
  font-size: 0.65rem;
}

ul.menu-list li.is-disabled a {
  color: $grey-light;
  cursor: not-allowed;
}

label.upload.is-fullwidth > div {
  width: 100%;
}

.is-pointer {
  cursor: pointer;
}

.information-button {
  margin-left: 10px;
}

.uploadButton input[type="file"] {
  cursor:pointer;
  position:absolute;
  top:0;
  opacity:0;
}

.uploadButton {
  position:relative;
  overflow:hidden;
  height:32px;
  cursor:pointer;
  width:32px;
}

.container-fluid {
  margin: 0 5%;
}

.modal.overflow-visible,
.modal.overflow-visible .modal-card,
.modal.overflow-visible .modal-card .modal-card-body,
.modal.overflow-visible .b-tabs,
.modal.overflow-visible .b-tabs .tab-content,
.modal.overflow-visible .b-tabs .tab-content .tab-item{
  overflow: visible;
}

.button.is-circle {
  border-radius: 50%;
}

.has-text-vertically-centered {
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown-item,
.navbar-item,
.button {
  svg+span,
  span+svg {
    margin-left: 7px;
  }
  & [type='button'] {
    background: white;
  }
}

.button.is-not-interactive {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    &.is-#{$name} {
      &,
      &:active,
      &:hover,
      &:focus {
        outline: $color;
        cursor: default;
        background-color: $color;
      }
    }
  }
}

.button.is-static.has-background-white {
  background-color: white;
}

button.is-fat {
  height: 3em;
}

// Needs to be higher than Handsontable header
.dropdown-menu {
  z-index: 110;
}

.z-index-zero {
  z-index: 0;
}

// .is-disabled

// LiquorTree display
.tree-text :first-child{
  margin-right: 10px;
}

.cards {
  .card {
    margin-bottom: 15px;
  }
}

//Datasets.vue Page custom styling changing router-link and dropdown autocomplete
.router-link-datasets a{
  color: #FFFFFF;
}
.router-link-datasets a:hover{
  color: #000000;
}
.router-link-datasets .dropdown-item {
  color: #000000;
}
.router-link-datasets .dropdown-item:hover {
  color: #000000;
}

.box.box-error {
  webkit-box-shadow: 0 2px 3px rgba(129, 10, 10, 0.15), 0 0 0 1px rgba(255, 0, 0, 0.15);
  box-shadow: 0 2px 3px rgba(129, 10, 10, 0.15), 0 0 0 1px rgba(255, 0, 0, 0.15);
}

.table-scrolling {
  display: inherit;
  overflow-x: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}

.result-renderer {
  padding-bottom: 50px;
}

.dial-left {
  position: fixed;
  left: 35px;
  bottom: 35px;
}

.dial-right {
  position: fixed;
  right: 35px;
  bottom: 35px;
}

.modal.has-width-auto .modal-content {
  width: auto;
}

label.label {
  svg[data-icon="asterisk"] {
    vertical-align: baseline;
  }
}

svg.has-svg-right {
  min-width: 24px; padding-right: 5px
}
svg.has-svg-left {
  min-width: 24px; padding-left: 5px
}

.field.has-icon {
  align-items: baseline;
  svg {
    min-width: 45px;
  }
}

.tags.has-text-centered {
  justify-content: center;
}

.modal.has-overflow-visible {
  .modal-content {
    overflow: visible;
    .modal-card {
      overflow: visible;
      .modal-card-body {
        overflow: visible;
      }
    }
  }
}

.is-flex.is-centered {
  justify-content: center;
}

// iframe height for OnlyOffice
#ooc iframe {
  height: 100% !important;
}

a.link {
  color: $link
}

a.subtle-link {
  color: $text;
  &:hover {
    text-decoration: underline;
  }
}

.margin-vertical-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

img.rounded-corners {
  border-radius: 5px;
}

.navbar-photo {
  display: inline;
  img {
    border-radius: 5px;
    margin-right: 15px;
  }
}

.break-long-words {
  word-break: break-word;
  white-space: pre-line;
}

figure.image.is-padded img {
  height: 80%;
  width: 80%;
  margin: auto;
}

// Hide zendesk help in onlyoffice
.hide-zendesk-help {
  iframe#launcher, iframe#webWidget {
    visibility: hidden;
  }
}

.newsfeed-entry {
  margin-bottom: 1em;
}

.control {
  button.delete {
    margin-left: 6px;
    margin-top: 2px;
  }
}


.text-editable:hover {
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 5px;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.mention-content.content p {
  margin-bottom: 0;
}

.full-width-page-with-sidebar {
  display: flex;
  flex-direction: row;
  width: 100vw;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1rem;
}

.select select option:disabled {
  color: #b5b5b5;
}

.textarea-no-resize textarea {
  resize: none;
}

.textarea-display {
  white-space: pre-wrap;
}

.field.input-required > .label {
  &::after {
    content: "*";
    color: #ff3860;
  }
}

.input-label-with-tooltip {
  flex-wrap: wrap
}

.modal {
  position: fixed !important;
  overflow: auto !important;
  .modal-background {
    position: fixed !important; // so page stays dark as we scroll
  }
  .modal-content, .modal-card, .modal-card-body {
    overflow: visible !important;
  }
}

.collapsable-text {
  overflow: hidden;
  transition: height 0.5s ease;
  &.collapsed {
    max-height: 150px;
    overflow: hidden;
    position: relative;
    &:before {
      pointer-events: none;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(rgba(255, 255, 255, 0) 30px, white);
    }
  }
}

.disabled-link {
  text-decoration: line-through;
  font-style: italic;
}

.valign-middle {
  vertical-align: middle;
}
.valign-sub {
  vertical-align: sub;
}

.disabled-area {
  filter: blur(1.5px);
  pointer-events: none;
  cursor: not-allowed;
}

.switch{
  &.left-label > label {
    margin-left: 0.4em;
    span.control-label{
      display:none;
    }
  }
}

.whats-on-your-mind {
  ul {
    list-style: inside;
  }
}

.notification-small {
  font-size: 0.75rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.input-instructions {
  font-style: italic;
  color: #707070;
  font-size: 0.80rem;
  margin: -0.75rem 0 0.75rem;
}

.capitalize {
  text-transform: capitalize;
}

.wrap-text {
  word-wrap: break-word;
}

@media print {
  .container-fluid {
    margin: 0;
  }

  .tile.is-parent {
    padding-top: 0;
  }

  .toast {
    display: none !important;
  }

  #launcher {
    display: none !important;
  }
}

.is-empty {
  color: #808080;
}

.is-default-option-row {
  background-color: #f7ea7f;
}

.flex-wrap {
  flex-wrap: wrap;
}

div.country-autocomplete > .autocomplete .dropdown-menu {
  display: block;
  min-width: 100%;
  max-width: unset;
}