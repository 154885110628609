// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import "~bulma-steps-component/bulma-steps";

// Set your colors
$section-padding: 1.5rem 1.5rem 3rem 1.5rem;
$primary: #3498D8; // Changed value
$primary-invert: findColorInvert($primary);
$primary-light: #BFBBBA;
$primary-light-invert: findColorInvert($primary-light);
$gray: #d3d3d3;
$gray-invert: findColorInvert($gray);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);
$body-background-color: whitesmoke;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
        "white": ($white, $black),
        "black": ($black, $white),
        "light": ($light, $light-invert),
        "gray": ($gray, $gray-invert),
        "dark": ($dark, $dark-invert),
        "primary": ($primary, $primary-invert),
        "primary-light": ($primary-light, $primary-light-invert),
        "info": ($info, $info-invert),
        "success": ($success, $success-invert),
        "warning": ($warning, $warning-invert),
        "danger": ($danger, $danger-invert),
        "twitter": ($twitter, $twitter-invert),
        "access-category": (#b836d9, $white)
);

@import "tribute";

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$radius-large: 3px;

:export {
  primary: $primary;
  info: $info;
  success: $success;
  warning: $warning;
  danger: $danger;
}
