









































.navbar-item img {
  max-height: 2.75rem;
}
