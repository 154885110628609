// If you change this list, you must update palette.ts
$palette_red: #ef476f;
$palette_purple: #8a84e2;
$palette_yellow: #dcd820;
$palette_green: #1aad5f;
$palette_blue: #25bbc0;
$palette_dark_blue: #1e72d0;
$palette_orange: #da7700;

$palette_colors: (
  "red": $palette_red,
  "purple": $palette_purple,
  "yellow": $palette_yellow,
  "green": $palette_green,
  "blue": $palette_blue,
  "dark-blue": $palette_dark_blue,
  "orange": $palette_orange
);

@each $color_name, $color_value in $palette_colors {

  .has-bg-palette-#{$color_name} {
    background: linear-gradient(135deg, scale-color($color_value, $lightness: +15%) 0%, scale-color($color_value, $lightness: -15%) 100%);
  }
  .has-text-palette-#{$color_name} {
    color: $color_value;
  }
}